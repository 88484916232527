import { renderEditTextarea } from "./CellTags";
import { getPageTitle } from "./individualView";
import { filter, isArray } from "lodash";
import {
  AddressEdit,
  TagDisplay,
  getRelatedRows,
  getTimeStampCell,
} from "./dataDisplay";
import { DatagridAddComment } from "./datagridAddComment";
import { EditRelatedCell } from "./relatedCell";
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  getGridStringOperators,
  GridComparatorFn,
  getGridDateOperators,
  getGridNumericOperators,
} from "@mui/x-data-grid-premium";
import _ from "lodash";
import { DisplayRelatedCell } from "./displayRelatedCell";
import { DisplayRelatedField, breakString } from "./relatedFields";
import { useContext } from "react";
import { RefetchContext } from "./refetchProvider";
import { GlobalContext } from "./globalContext";

export const passedColsParsed = (columns: any, apiRef: any) => {
  return columns?.map((col: any) => {
    if (col.type === "type") {
      const filterOperators = getGridStringOperators().map((operator) => {
        if (operator.value === "isAnyOf") {
          return {
            ...operator,
            getApplyFilterFn: (
              filterItem: GridFilterItem,
              column: GridColDef
            ) => {
              return (params: GridCellParams): boolean => {
                const val = params.value;
                if (!!!val) {
                  return false;
                }

                if (!!!filterItem?.value) return true;

                return filterItem.value.some((item: any) => {
                  if (Array.isArray(val)) {
                    return _.flatten(val).includes(item);
                  } else if (typeof val === "string") {
                    return val.includes(item);
                  } else {
                    return item === val;
                  }
                });
              };
            },
          };
        } else if (operator.value === "isEmpty") {
          return {
            ...operator,
            getApplyFilterFn: (
              filterItem: GridFilterItem,
              column: GridColDef
            ) => {
              return (params: GridCellParams): boolean => {
                const val = params.value;
                // Check for both an empty array and an empty string
                if (Array.isArray(val)) {
                  return val.length === 0;
                } else if (typeof val === "string") {
                  return val === "";
                }
                // Return false for any other type or condition
                return false;
              };
            },
          };
        }
        return operator;
      });

      return {
        renderEditCell: (params: any) => renderEditTextarea(params, apiRef),
        renderCell: (params: any) => {
          if (params.cellMode !== "view") return;
          return (
            <TagDisplay
              key={`${params?.row?._id}-${col.field}-tags`}
              field={col.field}
              options={params?.row[col.field]}
            />
          );
        },
        valueGetter: (value: any, row: any) => {
          const val = value;
          if (!!!val) {
            return [];
          }
          if (Array.isArray(val)) {
            return val;
          }
          if (typeof val === "string") {
            return val.split(",");
          }
          // If it's not an array or a string, return it in an array
          return [val];
        },
        filterOperators: filterOperators,
        type: col.type,
        display: col.display,
        field: col.field,
        headerName: col.headerName,
        editable: true,
        width: col.width,
        sortComparator: tagsComparator,
      };
    } else if (col.type.includes("related") && !col.type.includes("column")) {
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        display: col.display,
        width: col.width,
        editable: true,
        renderCell: (params: any) => {
          return <DisplayRelatedCell {...params} />;
        },
        valueGetter: (value: any, row: any) => {
          const viewId = col?.type?.split("-");
          if (Array.isArray(viewId) && viewId[1]) {
            const relatedRows = row?.relatedRows;
            if (Array.isArray(relatedRows)) {
              const filteredRelated = relatedRows?.filter((r: any) => {
                return r?.merc_viewId === viewId[1];
              });
              return filteredRelated.map((r: any) => {
                return r["merc_Header"] ?? "";
              });
            }
          }
          return Array.isArray(row?.relatedRows)
            ? row.relatedRows.map((row: any) => getPageTitle(row))
            : [];
        },
        renderEditCell: (params: any) => {
          return <EditRelatedCell {...params} />;
        },
        sortComparator: relatedComparator,
      };
    } else if (
      col?.type?.includes("related") &&
      col?.type?.includes("column")
    ) {
      let filterOperators;
      if (col?.relatedType === "date") {
        filterOperators = getGridDateOperators();
      } else if (col?.relatedType === "number") {
        filterOperators = getGridNumericOperators();
      } else {
        filterOperators = getGridStringOperators();
      }
      // Related Field
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        display: col.display,
        width: col.width,
        editable: false,
        renderCell: (params: any) => {
          return <DisplayRelatedField {...params} />;
        },
        valueGetter: (value: any, row: any) => {
          const parsedType = breakString(col.type);
          const relViewId = parsedType?.[1];
          const relColumn = parsedType?.[3];

          const filteredRelated = row?.relatedRows?.filter((r: any) => {
            return r.merc_viewId === relViewId;
          });

          const returnVal = filteredRelated?.[0]?.[relColumn];

          return returnVal;
        },
        filterOperators: filterOperators,
      };
    } else if (col.type === "lastActivity") {
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        width: col.width,
        editable: true,
        renderEditCell: (params: any) => {
          return (
            <DatagridAddComment row={params?.row} paperWidth={col.width} />
          );
        },

        renderCell: (params: any) => {
          if (!!params?.row?.latestActivity?.timestamp) {
            return `${getTimeStampCell(
              params?.row?.latestActivity
            )}: ${stripHtml(params?.row?.latestActivity?.commentId?.comment)}`;
          }
          return "";
        },
        valueGetter: (value: any, row: any) => {
          const dateString = !!row?.latestActivity?.timestamp
            ? new Date(row?.latestActivity?.timestamp)
            : "";

          return dateString;
        },
      };
    } else if (col.type === "createdAt") {
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        width: col.width,
        cellClassName: "mercero-cell",
        renderCell: (params: any) => {
          return new Date(params.row.createdAt).toLocaleDateString();
        },
        valueGetter: (value: any, row: any) => {
          return new Date(row.createdAt);
        },
      };
    } else if (col.type === "address") {
      return {
        field: col.field,
        headerName: col.headerName,
        editable: true,
        type: col.type,
        width: col.width,
        renderEditCell: (params: any) => <AddressEdit params={params} />,
      };
    } else if (col.type === "lastEmail") {
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        width: col.width,
        cellClassName: "mercero-cell",
        renderCell: (params: any) => {
          if (!params.row.latestEmail) {
            return "No activity";
          }
          return new Date(params.row.latestEmail).toLocaleDateString();
        },
        valueGetter: (value: any, row: any) => {
          if (!row.latestEmail) {
            return null;
          }
          return new Date(row.latestEmail);
        },
      };
    } else if (col.type === "phone") {
      return {
        field: col.field,
        headerName: col.headerName,
        type: col.type,
        display: col.display,
        width: col.width,
        editable: true,
        sortComparator: phoneNumberComparator,
        renderCell: (params: any) => {
          if (params.cellMode !== "view") return;

          if (col.type === "phone" && !!params.row[col.field]) {
            try {
              let phoneObj = JSON.parse(params.row[col.field]);
              let phoneElements: JSX.Element[] = [];

              if (Array.isArray(phoneObj)) {
                phoneElements = phoneObj.map((phone: any, index: number) => {
                  const formattedNumber = formatPhoneNumber(phone?.phoneNumber);
                  const phoneToCall = stripPhoneNumber(phone?.phoneNumber);
                  return (
                    <a
                      key={index}
                      href={`tel:${phoneToCall}`}
                      style={{
                        color: "#2e59a8",
                        fontWeight: 500,
                        display: "block",
                        marginBottom: "4px",
                      }}
                    >
                      {formattedNumber}
                    </a>
                  );
                });
              } else if (
                typeof phoneObj === "object" &&
                phoneObj?.phoneNumber
              ) {
                const formattedNumber = formatPhoneNumber(phoneObj.phoneNumber);
                const phoneToCall = stripPhoneNumber(phoneObj.phoneNumber);
                phoneElements.push(
                  <a
                    key={0}
                    href={`tel:${phoneToCall}`}
                    style={{
                      color: "#2e59a8",
                      fontWeight: 500,
                    }}
                  >
                    {formattedNumber}
                  </a>
                );
              } else {
                const formattedNumber = formatPhoneNumber(
                  params.row[col.field]
                );
                const phoneToCall = stripPhoneNumber(params.row[col.field]);

                if (formattedNumber.length !== 14) {
                  return formattedNumber;
                }

                return (
                  <a
                    href={`tel:${phoneToCall}`}
                    style={{
                      color: "#2e59a8",
                      fontWeight: 500,
                    }}
                  >
                    {formattedNumber}
                  </a>
                );
              }

              return <>{phoneElements}</>;
            } catch (e: any) {
              console.log("Not a phone obj", e);
            }

            // If JSON parsing fails, treat it as a single phone number
            const formattedNumber = formatPhoneNumber(params.row[col.field]);
            const phoneToCall = stripPhoneNumber(params.row[col.field]);

            if (formattedNumber.length !== 14) {
              return formattedNumber;
            }

            return (
              <a
                href={`tel:${phoneToCall}`}
                style={{
                  color: "#2e59a8",
                  fontWeight: 500,
                }}
              >
                {formattedNumber}
              </a>
            );
          }
        },
      };
    }

    return {
      field: col.field,
      headerName: col.headerName,
      type: col.type,
      display: col.display,
      width: col.width,
      editable: true,
      valueGetter: (value: any, row: any) => {
        if (value) {
          if (col.type === "date") {
            let field = row[col.field];
            try {
              return new Date(row[col.field]);
            } catch (err) {}
            return new Date();
          } else if (col.type === "number") {
            let field = row[col.field];
            try {
              field =
                typeof row[col.field] === "string"
                  ? parseFloat(row[col.field].replace(/,/g, ""))
                  : row[col.field];
            } catch (err) {
              console.log(err);
            }
            return field;
          }

          return row[col.field];
        } else if (col.type.includes("related")) {
          return row?.relatedRows?.map((row: any) => {
            return getPageTitle(row);
          });
        }
      },
      renderCell: (params: any) => {
        if (params.cellMode !== "view") return;
        if (col.type === "related") {
          return getRelatedRows(params?.row?.relatedRows);
        }
        if (col.type.includes("related")) {
          const viewId = col?.type?.split("-");

          if (Array.isArray(viewId) && viewId[1]) {
            const filteredRelated = params?.row?.relatedRows?.filter(
              (r: any) => r?.merc_viewId === viewId[1]
            );
            return getRelatedRows(filteredRelated);
          }
          return getRelatedRows(params?.row?.relatedRows);
        }
        if (col.type === "number") {
          let field = params.row[col.field];
          try {
            field =
              typeof params?.row[col.field] === "string"
                ? parseFloat(params?.row[col.field].replace(/,/g, ""))
                : params?.row[col.field];
          } catch (err) {
            console.log(err);
          }

          return (
            <span>{Number.isFinite(field) ? field?.toLocaleString() : ""}</span>
          );
        }

        if (col.type === "email" && !!params.row[col.field]) {
          try {
            let emailObj = JSON.parse(params.row[col.field]);
            let emailElements: JSX.Element[] = [];

            if (Array.isArray(emailObj)) {
              emailElements = emailObj.map((email: any, index: number) => (
                <a
                  key={index}
                  href={`mailto:${email?.emailAddress}`}
                  style={{
                    color: "#2e59a8",
                    fontWeight: 500,
                    display: "block",
                    marginBottom: "4px",
                  }}
                >
                  {email?.emailAddress}
                </a>
              ));
            } else if (typeof emailObj === "object" && emailObj?.emailAddress) {
              emailElements.push(
                <a
                  key={0}
                  href={`mailto:${emailObj.emailAddress}`}
                  style={{
                    color: "#2e59a8",
                    fontWeight: 500,
                  }}
                >
                  {emailObj.emailAddress}
                </a>
              );
            }

            return <>{emailElements}</>;
          } catch (e: any) {
            console.log("Not an email obj", e);
          }

          return (
            <a
              href={`mailto:${params.row[col.field]}`}
              style={{
                color: "#2e59a8",
                fontWeight: 500,
              }}
            >
              {params.row[col.field]}
            </a>
          );
        }

        if (col.type === "link" && !!params.row[col.field]) {
          const formattedLink = formatLink(params.row[col.field]);

          if (!formattedLink) {
            return params.row[col.field];
          }

          return (
            <a
              href={formattedLink}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#2e59a8",
                fontWeight: 500,
              }}
            >
              {params.row[col.field]}
            </a>
          );
        }

        if (col.type === "lastActivity") {
          return getTimeStampCell(params?.row?.latestActivity);
        }

        if (col.type === "date") {
          const date = new Date(params.row[col.field]);
          if (isNaN(date.getTime())) {
            return "";
          }
          return date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          });
        }
      },
    };
  });
};

function stripHtmlTags(input: string) {
  return input?.replace(/<\/?[^>]+(>|$)/g, "") || "";
}

const phoneNumberComparator: GridComparatorFn<string> = (
  v1: string,
  v2: string
) => Number(stripPhoneNumber(v1)) - Number(stripPhoneNumber(v2));

const relatedComparator: GridComparatorFn<any> = (
  v1: any,
  v2: any,
  cellParams1: any,
  cellParams2: any
) => {
  return tagsComparator(v1, v2, cellParams1, cellParams2);
};

const tagsComparator: GridComparatorFn<any> = (v1: [string], v2: [string]) => {
  if ((_.isNil(v1) || _.isEmpty(v1)) && (_.isNil(v2) || _.isEmpty(v2))) {
    return 0;
  }

  if (_.isNil(v1) || _.isEmpty(v1)) {
    return 1;
  }
  if (_.isNil(v2) || _.isEmpty(v2)) {
    return -1;
  }

  if (
    _.chain(v1)
      .orderBy((o) => o, ["asc"])
      .first()
      .toLower()
      .value() ==
    _.chain(v2)
      .orderBy((o) => o, ["asc"])
      .first()
      .toLower()
      .value()
  ) {
    return 0;
  }

  if (
    _.chain(v1)
      .orderBy((o) => o, ["asc"])
      .first()
      .toLower()
      .value() >
    _.chain(v2)
      .orderBy((o) => o, ["asc"])
      .first()
      .toLower()
      .value()
  ) {
    return 1;
  } else {
    return -1;
  }
};

export function GetHeaderText(row: any, parsedColumns: any) {
  const name = parsedColumns?.find((c: any) => c?.type === "name");
  const firstName = parsedColumns?.find((c: any) => c?.type === "firstName");
  const lastName = parsedColumns?.find((c: any) => c?.type === "lastName");
  const header1 = parsedColumns?.find((c: any) => c?.type === "header1");
  const header2 = parsedColumns?.find((c: any) => c?.type === "header2");
  const address = parsedColumns?.find((c: any) => c?.type === "address");

  const newHeader1 = parsedColumns?.find((c: any) => c?.display === "header1");
  const newHeader2 = parsedColumns?.find((c: any) => c?.display === "header2");

  if (!!!row || !!!parsedColumns) return "";

  if (!!newHeader1) {
    return !!newHeader2
      ? `${row[newHeader1.field] ?? ""} ${row[newHeader2?.field] ?? ""}`
      : row[newHeader1.field];
  }

  if (!!header1) {
    return !!header2
      ? `${row[header1.field] ?? ""} ${row[header2?.field] ?? ""}`
      : row[header1.field];
  }

  if (!!name) {
    return row[name.field];
  }

  if (!!firstName) {
    return !!lastName
      ? `${row[firstName.field] ?? ""} ${row[lastName?.field] ?? ""}`
      : row[firstName.field];
  }

  if (!!address) {
    return row[address.field];
  }

  const pageTitle = Object.keys(row).filter(
    (item: any) => item !== "_id" && item !== "id" && item !== "latestActivity"
  );

  return row["name"] || row["Name"] || row[pageTitle[0]];
}

export function GetSubheaderText(row: any, columns: any, relatedRows?: any) {
  const subheader1 = columns?.find((c: any) => c?.type === "subheader1");
  const subheader2 = columns?.find((c: any) => c?.type === "subheader2");
  const company = columns?.find((c: any) => c?.type === "company");

  const newSubheader1 = columns?.find((c: any) => c?.display === "subheader1");
  const newSubheader2 = columns?.find((c: any) => c?.display === "subheader2");

  if (!!newSubheader1) {
    if (newSubheader1?.type?.includes("related")) {
      return <RelatedSubheaderText row={row} column={newSubheader1} />;
    }

    return !!newSubheader2
      ? `${row[newSubheader1.field] ?? ""} ${row[newSubheader2?.field] ?? ""}`
      : row[newSubheader1.field];
  }

  if (!!subheader1) {
    return !!subheader2
      ? `${row[subheader1.field] ?? ""} ${row[subheader2?.field] ?? ""}`
      : row[subheader1.field];
  }

  if (!!company) {
    return row[company.field];
  }

  const pageTitle = Object.keys(row).filter(
    (item: any) =>
      item !== "_id" &&
      item !== "id" &&
      item !== "relatedRows" &&
      item !== "latestActivity"
  );

  return row[pageTitle[1]] || "";
}

export function RelatedSubheaderText(props: any) {
  const { row, column } = props;
  const refetchContext = useContext(RefetchContext);
  const globalContext = useContext(GlobalContext);

  const viewId = column?.type?.split("-");

  if (isArray(viewId) && !!viewId[1]) {
    const relatedRows =
      row?.relatedRows?.map((row: any) => {
        try {
          const parsedRow = JSON.parse(row?.rowObject);

          return {
            ...parsedRow,
            merc_viewId: row?.viewId?._id,
          };
        } catch (e) {
          return row;
        }
      }) ||
      // @ts-ignore
      globalContext?.rows[row?.viewId?._id]?.get(row?._id)?.relatedRows ||
      // @ts-ignore
      globalContext?.rows[row?.merc_viewId]?.get(row?._id)?.relatedRows;

    const filteredRelated = relatedRows?.filter((r: any) => {
      return r?.merc_viewId === viewId?.[1];
    });

    const relView = refetchContext?.allViews?.find((v: any) => {
      return v?._id === viewId?.[1];
    });

    const headerCol = relView?.columns?.find(
      (c: any) => c?.display === "header1"
    );

    if (!headerCol && filteredRelated?.length > 0) {
      // Find the first value that isn't an ID
      const firstNonIdValue = Object.entries(row).find(([key, value]) => {
        return (
          !key.toLowerCase().includes("id") &&
          value !== null &&
          value !== undefined
        );
      });
      return <>{firstNonIdValue ? firstNonIdValue[1] : ""}</>;
    }

    return filteredRelated?.[0]?.[headerCol?.field] || "";
  }

  return <></>;
}

export function ReturnVisibleColumns(columns: any, initialState: any) {
  return columns?.filter((item: any) => {
    const field = item?.field;

    if (field === "relatedRows" || field === "_id" || field === "id") {
      return false;
    }

    if (!!!initialState?.columns?.columnVisibilityModel) {
      return true;
    }

    if (
      !!initialState?.columns?.columnVisibilityModel &&
      (initialState?.columns?.columnVisibilityModel[field] === undefined ||
        initialState?.columns?.columnVisibilityModel[field] === true)
    ) {
      return true;
    }
    return false;
  });
}

export function ReturnHiddenColumns(columns: any, initialState: any) {
  return columns?.filter((item: any) => {
    const field = item?.field;

    if (field === "relatedRows" || field === "_id" || field === "id") {
      return false;
    }

    if (
      initialState?.columns?.columnVisibilityModel[field] !== undefined &&
      initialState?.columns?.columnVisibilityModel[field] !== true
    ) {
      return true;
    }
    return false;
  });
}

export function formatPhoneNumber(phone: string) {
  // Remove all non-numeric characters from the phone number
  let cleaned = ("" + phone).replace(/\D/g, "");

  if (cleaned.length === 11 && _.startsWith(cleaned, "1")) {
    cleaned = cleaned.substring(1);
  }

  // Ensure the cleaned phone number is exactly 10 digits
  if (cleaned.length !== 10) {
    return phone;
  }

  // Split the cleaned number into its constituent parts
  const areaCode = cleaned.substring(0, 3);
  const centralOfficeCode = cleaned.substring(3, 6);
  const stationCode = cleaned.substring(6, 10);

  // Return the formatted phone number
  return `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
}

export function stripPhoneNumber(phone: string) {
  // Remove all non-numeric characters from the phone number
  const cleaned = ("" + phone).replace(/\D/g, "");

  // Ensure the cleaned phone number is exactly 10 digits
  if (cleaned.length !== 10) {
    return "";
  }

  // Split the cleaned number into its constituent parts
  return cleaned;
}

export function stripHtml(htmlString: string) {
  if (!!!htmlString) return "";
  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the string as HTML
  const doc = parser.parseFromString(htmlString, "text/html");
  // Return the text content, which will be free of HTML tags and entities
  return doc?.body.textContent || "";
}

function isValidURL(url: string) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }

  try {
    // Try constructing a new URL object, which will throw if the URL is invalid
    new URL(url);

    return true;
  } catch (e) {
    // If the URL constructor throws, the URL is invalid
    return false;
  }
}

// function formatLink(url: string) {
//   // Trim whitespace from both ends of the string
//   var trimmedUrl = url.trim();

//   // May want to append htts:// if it doesn't exist
//   if (!isValidURL(trimmedUrl)) {
//     return null;
//   }

//   if (!trimmedUrl.startsWith("http://") && !trimmedUrl.startsWith("https://")) {
//     return "https://" + trimmedUrl;
//   }
//   return trimmedUrl;
// }

function formatLink(url: string) {
  // Trim whitespace from both ends of the string
  var trimmedUrl = url.trim();

  // Check if the URL is valid
  if (!isValidURL(trimmedUrl)) {
    return null;
  }

  // Append "https://" if it doesn't start with "http://" or "https://"
  if (!trimmedUrl.startsWith("http://") && !trimmedUrl.startsWith("https://")) {
    if (!trimmedUrl.startsWith("www.")) {
      trimmedUrl = "www." + trimmedUrl; // Ensure it starts with "www."
    }
    trimmedUrl = "https://" + trimmedUrl; // Then prepend the protocol
  }

  return trimmedUrl;
}

const safeJSONParse = (jsonString: any, fallback = undefined) => {
  try {
    return jsonString ? JSON.parse(jsonString) : fallback;
  } catch (error) {
    console.error("JSON parsing error:", error);
    return fallback;
  }
};

const safeSplit = (value: any, separator = ",", fallback = []) => {
  if (typeof value === "string") {
    return value.split(separator);
  }
  console.warn("Split attempted on non-string value:", value);
  return fallback;
};

export function cleanFilterModel(filterModel: any, columns: any) {
  const filterModelItems =
    filterModel?.items?.filter((item: any) => {
      const columnType = columns?.find(
        (col: any) => col.field === item.field
      )?.type;
      return shouldIncludeInFilter(item, columnType);
    }) || [];

  return {
    ...filterModel,
    items: filterModelItems,
  };
}

const shouldIncludeInFilter = (item: any, columnType: string) => {
  const stringType = [
    "string",
    "firstName",
    "name",
    "email",
    "company",
    "lastName",
    "type",
    "related",
    "lastActivity",
    "header1",
    "header2",
    "subheader1",
    "subheader2",
    "address",
    "phone",
    "contactName",
    "companyName",
    "link",
  ];
  const stringOperators = [
    "contains",
    "equals",
    "startsWith",
    "endsWith",
    "isEmpty",
    "isNotEmpty",
    "isAnyOf",
  ];
  const numberOperators = [
    "=",
    "!=",
    ">",
    "<",
    ">=",
    "<=",
    "isEmpty",
    "isNotEmpty",
    "isAnyOf",
  ];
  const booleanOperators = ["is"];
  const dateOperators = [
    "is",
    "not",
    "after",
    "onOrAfter",
    "before",
    "onOrBefore",
    "isEmpty",
    "isNotEmpty",
  ];

  if (item?.field === "merc_Color") return true;
  if (columnType?.includes("related") && columnType?.includes("column"))
    return true;
  if (
    (stringType?.includes(columnType) || columnType?.includes("related")) &&
    stringOperators?.includes(item.operator)
  )
    return true;
  if (columnType === "number" && numberOperators?.includes(item.operator))
    return true;
  if (columnType === "date" && dateOperators?.includes(item.operator))
    return true;
  if (columnType === "boolean" && booleanOperators?.includes(item.operator))
    return true;

  return false;
};
