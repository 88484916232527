import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Editor } from "@tinymce/tinymce-react";
import { apiURL } from "./consts";
import { useLocation, useNavigate } from "react-router-dom";
import { MessageStatus, RefetchContext } from "./refetchProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPageTitle } from "./individualView";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LoadingScreen from "./loadingScreen";
import _ from "lodash";
import { GetHeaderText } from "./DatagridFunctions";

interface AutocompleteSuggestion {
  trigger: string;
  value: string;
  text: string;
}

interface Column {
  field: string;
  headerName: string;
  width: number;
}

const getAutocompleteSuggestions = (cols: any): AutocompleteSuggestion[] => {
  const filterOutItems = cols.filter((column: any) => {
    return (
      column?.field !== "id" &&
      column?.field !== "_id" &&
      column?.field !== "actions" &&
      column?.field !== "__check__" &&
      column?.field !== "notes" &&
      column?.field !== "date" &&
      column?.field !== "new-column" &&
      column !== "id" &&
      column !== "_id" &&
      column !== "actions" &&
      column !== "__check__" &&
      column !== "notes" &&
      column !== "date" &&
      column !== "new-column"
    );
  });

  return filterOutItems.map((column: any) => ({
    type: "autocompleteitem",
    value: `{{${column?.field?.toUpperCase() || column?.toUpperCase()}}}`,
    text: column?.headerName || column,
  }));
};

export default function NewSendEmail() {
  const { state } = useLocation();
  const context = useContext(RefetchContext);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [previewEmails, setPreviewEmails] = useState<any[]>([]);
  const [topLevelErrors, setTopLevelErrors] = useState<any[]>([]);
  const [subjecthasTags, setSubjectHasTags] = useState(false);
  const [bodyhasTags, setBodyHasTags] = useState(false);
  const [emailRequestData, setEmailRequestData] = useState<any>();
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  const [parsedColumns, setParsedColumns] = useState<any[]>([]);

  const [canTakeLong, setCanTakeLong] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [exceedsAttachmentSizeLimit, setExceedsAttachmentSizeLimit] =
    useState<boolean>(false);
  const [signatureRes, setSignatureRes] = useState<any>({});
  const [timeoutSeconds, setTimeoutSeconds] = useState<number>(600);

  const [ccEmail, setCcEmail] = useState<string>("");

  const emailColumn = !!state?.emailColumn && JSON.parse(state?.emailColumn);

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();

        await fetch(`${apiURL}row?q=${searchQuery}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setSearchResults([
              ...result.map((r: any) => {
                return {
                  _id: r._id,
                  ...JSON.parse(r.rowObject),
                  relatedRows: r.relatedRows,
                };
              }),
            ]);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [searchQuery, getAccessTokenSilently]);

  useEffect(() => {
    if (state?.selectedRows) {
      setSelectedRows(JSON.parse(state?.selectedRows));
    }
  }, [state?.selectedRows]);

  useEffect(() => {
    try {
      const newCols = JSON.parse(state?.columns);
      setParsedColumns(newCols);
    } catch (e) {
      console.log("Failed to parse columns in newSendEmail");
    }
  }, [state?.columns]);

  const navigate = useNavigate();

  const editorRef = useRef(null);
  const subjectEditorRef = useRef(null);
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState<any>([]);
  const [signature, setSignature] = useState<any>(undefined);

  const [ccValid, setCcValid] = useState<boolean>(true);
  // const [ccErrorText, setCcErrorText] = useState<any>(null);
  // const [checkCcEmail, setCheckCcEmail] = useState<number>(0);
  const [emailConfig, setEmailConfig] = useState<undefined | String>(undefined);
  const [previewUrl, setPreviewUrl] = useState<undefined | String>(undefined);
  const [sendUrl, setSendUrl] = useState<undefined | String>(undefined);
  const [isEmailSetup, setIsEmailSetup] = useState<boolean>(true);
  const [isSendgridSetup, setIsSendgridSetup] = useState<boolean>(true);

  const EMAIL_LIMIT = 50;

  useEffect(() => {
    if (ccEmail.length > 3) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const ccEmails = _.chain(ccEmail)
        .split(",")
        .map((o) => _.trim(o))
        .value();
      if (_.some(ccEmails, (o) => !emailRegex.test(o))) {
        setCcValid(false);
      } else {
        setCcValid(true);
      }
    } else {
      setCcValid(true);
      // setCcErrorText(null);
    }
  }, [ccEmail]);

  useEffect(() => {
    const initializeEmailConfig = async () => {
      const accessToken = await getAccessTokenSilently();
      let resp = await fetch(`${apiURL}email/is-setup`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });
      const emailSpecs = await resp.json();
      const isEmailGoodToGo = _.get(emailSpecs, "validToken", false);
      setIsEmailSetup(
        _.chain(emailSpecs)
          .get("primaryEmail")
          .isNil()
          .thru((o) => !o)
          .value()
      );

      resp = await fetch(`${apiURL}sendgridInterface/read-sender-identity`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });
      const sendgridSender = await resp.json();
      const isSendgridGoodToGo = _.get(
        sendgridSender,
        "senderProfileVerified",
        false
      );
      setIsSendgridSetup(
        _.chain(sendgridSender)
          .get("senderProfile")
          .isNil()
          .thru((o) => !o)
          .value()
      );

      if (isEmailGoodToGo && isSendgridGoodToGo) {
        setEmailConfig("all");
      } else if (isEmailGoodToGo && !isSendgridGoodToGo) {
        setEmailConfig("email");
        setPreviewUrl(`${apiURL}email/preview`);
        setSendUrl(`${apiURL}email`);
      } else if (!isEmailGoodToGo && isSendgridGoodToGo) {
        setEmailConfig("sendgrid");
        setPreviewUrl(`${apiURL}sendgridInterface/preview`);
        setSendUrl(`${apiURL}sendgridInterface/campaign`);
      } else {
        setEmailConfig("none");
      }
    };
    initializeEmailConfig();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const updateUrls = () => {
      if (_.chain(selectedRows).size().lte(EMAIL_LIMIT).value()) {
        setPreviewUrl(`${apiURL}email/preview`);
        setSendUrl(`${apiURL}email`);
      } else {
        setPreviewUrl(`${apiURL}sendgridInterface/preview`);
        setSendUrl(`${apiURL}sendgridInterface/campaign`);
      }
    };
    if (_.eq(emailConfig, "all")) {
      updateUrls();
    }
  }, [selectedRows, emailConfig]);

  const autoCompleteItems = () =>
    getAutocompleteSuggestions(
      typeof state?.columns === "string" && JSON.parse(state.columns)
    );

  const handleEditorInit = (editor: any) => {
    // Autocompleter configuration
    editor.ui.registry.addAutocompleter("myautocompleter", {
      ch: "{", // Add this line
      minChars: 0,
      columns: 1,
      //   smatches: /^\{/,
      fetch: (pattern: any) => {
        // const items = [
        //   { type: "autocompleteitem", value: "{{apple}}", text: "Apple" },
        //   { type: "autocompleteitem", value: "{{banana}}", text: "Banana" },
        //   { type: "autocompleteitem", value: "{{cherry}}", text: "Cherry" },
        // ];

        const items = autoCompleteItems();

        return new Promise((resolve) => {
          const results = items.filter((item) => {
            return item?.text?.toLowerCase().includes(pattern?.toLowerCase());
          });
          resolve(results);
        });
      },
      onAction: (autocompleteApi: any, rng: any, value: any) => {
        editor.selection.setRng(rng);
        editor.insertContent(value);
        autocompleteApi.hide();
      },
    });
  };

  useEffect(() => {
    const getSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const sigRes = await getSignature.json();
      setSignature(sigRes.signature);
    };
    if (!!!signature) {
      getSignature();
    }
  }, []);

  useEffect(() => {
    const fetchSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const signatureRes = await getSignature.json();
      setSignatureRes(signatureRes);
    };
    fetchSignature();
  }, []);

  useEffect(() => {
    const processEmailSize = async () => {
      //@ts-ignore
      const msg = `${message} || ""}\n\n${
        !!signatureRes?.signature ? signatureRes?.signature : ""
      }`;

      let currentSize = subject.length + msg.length;
      for (const f of file) {
        currentSize += f.size;
      }

      if (currentSize > 25 * 1000 * 1000) {
        setExceedsAttachmentSizeLimit(true);
      } else {
        setExceedsAttachmentSizeLimit(false);
      }

      const currentSizeMBytes = currentSize / (1000 * 1000);

      const OUTLOOK_THROTTLING_LIMIT_MB = 150;
      const minBatches = _.chain(selectedRows)
        .size()
        .multiply(currentSizeMBytes)
        .divide(0.6 * OUTLOOK_THROTTLING_LIMIT_MB)
        .ceil()
        .toInteger()
        .value();
      if (_.chain(minBatches).gt(11).value()) {
        setCanTakeLong(true);
      } else {
        setCanTakeLong(false);
      }

      setTimeoutSeconds(2 * minBatches * 300);
    };

    processEmailSize();
  }, [file, message, subject, selectedRows, signatureRes]);

  if (!!!selectedRows || !!!state.columns) {
    return <div>Select contacts from your contact list to send an email</div>;
  }

  let isMissingEmail = selectedRows.some(
    (row) => !!!row["Email"] && !!emailColumn && !!!row[emailColumn]
  );

  const fromEmail =
    !!user && !!user["https://crm.mercero-api.com/primaryEmailAddress"] ? (
      user["https://crm.mercero-api.com/primaryEmailAddress"]
    ) : (
      <span style={{ color: "red" }}>
        Please connect an email in your settings page.
      </span>
    );

  const hasTags = subjecthasTags || bodyhasTags;

  // const debouncedSetCheckCcEmail = _.debounce(setCheckCcEmail, 500);

  return (
    <>
      {emailSendLoading && <LoadingScreen />}
      <div className="pageHeader" style={{ height: "56px" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "56px" }}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontSize={"14px"}
            sx={{ marginLeft: "5px" }}
          >
            New Email ({selectedRows.length})
            {/* New Email to {!!selectedRows[0] && selectedRows[0]["Name"]}{" "}
            {selectedRows.length > 1 && `and ${selectedRows.length - 1} more`} */}
          </Typography>
        </Box>
      </div>
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <CssBaseline />
        {!isEmailSetup && !isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent because an email service
              provider (Outlook / Gmail / Sendgrid) has not been setup. Please
              contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because of errors
              retrieving a token from your email service provider nor is your
              sendgrid email verified. Please contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && !isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because of errors
              retrieving a token from your email service. Please contact
              support.
            </Alert>
          </Grid>
        )}
        {!isEmailSetup && isSendgridSetup && _.eq(emailConfig, "none") && (
          <Grid item xs={12}>
            <Alert severity="error">
              Emails cannot be previewed or sent likely because your sendgrid
              email is not verified. Please contact support.
            </Alert>
          </Grid>
        )}
        {isEmailSetup && _.eq(emailConfig, "sendgrid") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Error retrieving token from email service provider. It could be
              because your password has changed or some such reason. Defaulting
              to sendgrid.
            </Alert>
          </Grid>
        )}
        {isSendgridSetup && _.eq(emailConfig, "email") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Error retrieving token from email service provider. Defaulting to
              email.
            </Alert>
          </Grid>
        )}
        {isMissingEmail && (
          <Grid item xs={12}>
            <Alert severity="error">
              One or more records are missing an email. Please remove them from
              the recipients list or add in an email address.
            </Alert>
          </Grid>
        )}
        {selectedRows.length > 50 && _.eq(emailConfig, "email") && (
          <Grid item xs={12}>
            <Alert severity="warning">
              We don't recommend sending more than 50 emails at a time to avoid
              spam filters.
            </Alert>
          </Grid>
        )}
        {canTakeLong && (
          <Grid item xs={12}>
            <Alert severity="warning">
              It could take over an hour to send this many emails. Please be
              patient.
            </Alert>
          </Grid>
        )}
        {exceedsAttachmentSizeLimit && (
          <Grid item xs={12}>
            <Alert severity="error">Attachment size exceeds 25MB limit</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <ShowPreviewEmails
            previewEmails={previewEmails}
            setPreviewEmails={setPreviewEmails}
            topLevelErrors={topLevelErrors}
            setTopLevelErrors={setTopLevelErrors}
            formData={emailRequestData}
            context={context}
            navigate={navigate}
            emailConfig={emailConfig}
            sendUrl={sendUrl}
          />
        </Grid>
        <Grid item xs={9} style={{ paddingTop: "5px" }}>
          <div style={{ width: "100%", padding: "5px 30px" }}>
            {/* <TextField
              style={{ width: "100%" }}
              id="standard-basic"
              label="To"
              variant="standard"
              value={Array.from(selectedRows)
                .map((row) => {
                  return row[1]["Email"];
                })
                .toString()}
            /> */}
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={1}>
                <Typography fontSize={"14px"}>
                  <b>From </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Chip
                  label={`${user?.name} (${fromEmail})`}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={1}>
                <Typography fontSize={"14px"}>
                  <b>CC </b>
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  style={{ width: "100%" }}
                  id="standard-basic"
                  variant="standard"
                  value={ccEmail}
                  onChange={(e) => {
                    setCcEmail(e.target.value);
                  }}
                  placeholder="Enter CC here"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  type={"email"}
                  error={!ccValid}
                  helperText={!ccValid ? "Invalid Email" : ""}
                  onKeyUp={(e) => {
                    // debouncedSetCheckCcEmail(checkCcEmail + 1);
                  }}
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            {/* <Typography contentEditable={true} style={{ marginBottom: "20px" }}>
              <b>Subject</b>
            </Typography> */}
            {/* <TextField
              style={{ width: "100%", marginBottom: "20px" }}
              id="standard-basic"
              variant="standard"
              value={subject}
              autoFocus
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              placeholder="Subject"
              InputProps={{
                disableUnderline: true,
                style: { fontWeight: "bold" },
              }}
            /> */}
            <div className="subjectEditorContainer">
              <Editor
                apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
                onChange={(e) => {
                  setSubjectHasTags(e.target.getContent().includes("{{"));
                  // setSubject(e.target.getContent());
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                  // console.log(event.target);
                }}
                onKeyUp={(event) => {
                  setSubject(event.target.innerText);
                }}
                key={"subject"}
                // @ts-ignore
                onInit={(evt, editor) => (subjectEditorRef.current = editor)}
                // initialValue="<p>Type your email here</p>"
                init={{
                  placeholder: "Subject here...",
                  height: "50px",
                  width: "100%",
                  menubar: false,
                  toolbar: false,
                  nowrap: true,
                  newline_behavior: "block",
                  forced_root_block: "",
                  // force_br_newlines: true,
                  // forced_root_block: "div",
                  force_p_newlines: false,
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px, line-height: 1; }" +
                    ".tox-tinymce { border: none !important; margin-bottom: 0px; height: 50px !important; }",
                  setup: handleEditorInit,
                }}
              />
            </div>
            <Editor
              apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
              onChange={(e) => {
                setBodyHasTags(e.target.getContent().includes("{{"));
              }}
              onKeyUp={(event) => {
                setMessage(event.target.innerText);
              }}
              key={"body"}
              // @ts-ignore
              onInit={(evt, editor) => (editorRef.current = editor)}
              // initialValue="<p>Type your email here</p>"
              init={{
                placeholder: "Type your email here",
                browser_spellcheck: true,
                height: 400,
                width: "100%",
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image",
                force_br_newlines: true,
                forced_root_block: "div",
                force_p_newlines: false,
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px, line-height: 1; }" +
                  ".tox-tinymce { border: none !important; }",
                setup: handleEditorInit,
                automatic_uploads: true,
                images_upload_url: `${apiURL}email/upload-attachment`,
                images_upload_handler: async (blobInfo, progress) => {
                  try {
                    const accessToken = await getAccessTokenSilently();
                    return new Promise((resolve, reject) => {
                      const xhr = new XMLHttpRequest();
                      xhr.withCredentials = false;
                      xhr.open("POST", `${apiURL}email/upload-attachment`);

                      xhr.setRequestHeader(
                        "Authorization",
                        `Bearer ${accessToken}`
                      );

                      xhr.upload.onprogress = (e) => {
                        progress((e.loaded / e.total) * 100);
                      };

                      xhr.onload = () => {
                        if (xhr.status === 403) {
                          reject({
                            message: "HTTP Error: " + xhr.status,
                            remove: true,
                          });
                          return;
                        }

                        if (xhr.status < 200 || xhr.status >= 300) {
                          reject("HTTP Error: " + xhr.status);
                          return;
                        }

                        try {
                          const response = JSON.parse(xhr.responseText);
                          if (response && response.url) {
                            resolve(response.url);
                          } else {
                            console.error(
                              "Unexpected response format:",
                              response
                            );
                            reject("Invalid server response: URL not found");
                          }
                        } catch (error) {
                          console.error(
                            "Error parsing JSON:",
                            xhr.responseText
                          );
                          reject("Invalid JSON response from server");
                        }
                      };

                      xhr.onerror = () => {
                        reject(
                          "Image upload failed due to a XHR Transport error. Code: " +
                            xhr.status
                        );
                      };

                      const formData = new FormData();
                      formData.append(
                        "file",
                        blobInfo.blob(),
                        blobInfo.filename()
                      );

                      xhr.send(formData);
                    });
                  } catch (error) {
                    console.error("Error getting access token:", error);
                    throw error;
                  }
                },
              }}
            />
            <div
              style={{ fontSize: "75%", paddingLeft: "25px", opacity: ".8" }}
              dangerouslySetInnerHTML={{ __html: signature }}
            />
          </div>
        </Grid>
        <Grid item xs={3} style={{ maxHeight: "calc(100vh - 200px)" }}>
          <Typography variant="h6" gutterBottom>
            Recipient List
          </Typography>
          <Autocomplete
            // key={searchResults.length}
            sx={{
              border: "none",
              outline: "none",
              "& .MuiChip-root": {
                display: "none",
              },
              "& .MuiPaper-root": {
                padding: "0px",
              },
              "& .MuiAutocomplete-option": {
                padding: "0px",
              },
              "& .MuiAutocomplete-clearIndicator": {
                display: "none",
              },
            }}
            multiple
            options={searchResults}
            getOptionLabel={(option: any) => {
              return (
                GetHeaderText(option, parsedColumns) ||
                getPageTitle(option) ||
                ""
              );
            }} // Adjust this based on the structure of your result object
            value={selectedRows} //selectedRows
            loading={loading}
            onChange={(_: any, value: any) => {
              // setSelectedOptions(value)
              setSelectedRows(value);
              // const newSelectedRows = [...selectedRows];
              // newSelectedRows.push(value);
              // setSelectedRows(newSelectedRows);
            }}
            onInputChange={(_: any, value: any) => setSearchQuery(value)}
            renderOption={(props: any, option: any) => (
              <Box
                component="li"
                {...props}
                sx={{
                  fontSize: "1rem",
                  padding: "10px !important",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 255, 0.1)",
                  },
                }}
              >
                {getPageTitle(option)}
              </Box>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                // label="Search"
                placeholder="Add more recipients"
                variant="outlined"
                //value={searchQuery}
              />
            )}
          />
          <List
            style={{ maxHeight: "calc(100% - 100px)", overflowY: "scroll" }}
          >
            {selectedRows.map((row) => {
              return (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        const newSelectedRows = [...selectedRows];
                        newSelectedRows.splice(newSelectedRows.indexOf(row), 1);
                        setSelectedRows(newSelectedRows);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={
                      GetHeaderText(row, parsedColumns) || "No name found"
                    }
                    secondary={
                      row[emailColumn] || row["Email"] || "No email found"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

          {file.length === 0 && (
            <Button
              variant="outlined"
              startIcon={<AttachFileIcon />}
              component={"label"}
            >
              Attach Files
              <input
                hidden
                multiple
                type="file"
                onChange={(event) => {
                  // @ts-ignore
                  const filesBuff = _.chain(event)
                    .get("target.files", [])
                    // @ts-ignore
                    .reduce((r, v) => {
                      r.push(v);
                      return r;
                    }, [])
                    .value();
                  // @ts-ignore
                  setFile(filesBuff);
                }}
              />
            </Button>
          )}
          {file.length > 0 && (
            <>
              {" "}
              {file?.map((singleFile: any, index: number) => {
                return (
                  <Button
                    style={{ marginRight: "10px" }}
                    variant="outlined"
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      const newFile = [...file];
                      newFile.splice(index, 1);
                      setFile(newFile);
                    }}
                  >
                    {singleFile?.name}
                  </Button>
                );
              })}
              <Button aria-label="delete" component={"label"}>
                <AddIcon />
                <input
                  hidden
                  multiple
                  type="file"
                  onChange={(event) => {
                    // @ts-ignore
                    const filesBuff = _.chain(event)
                      .get("target.files", [])
                      // @ts-ignore
                      .reduce((r, v) => {
                        r.push(v);
                        return r;
                      }, [])
                      .value();
                    // @ts-ignore
                    setFile([...file, ...filesBuff]);
                  }}
                />
              </Button>
            </>
          )}
          <Button
            variant="contained"
            style={{ float: "right" }}
            onClick={async () => {
              try {
                setEmailSendLoading(true);
                const accessToken = await getAccessTokenSilently();

                const formData = new FormData();
                if (!_.isEmpty(file)) {
                  for (const f of file) {
                    formData.append("file", f);
                  }
                }

                formData.append(
                  "subject",
                  // @ts-ignore
                  subject
                );
                formData.append(
                  "rowIds",
                  JSON.stringify(selectedRows.map((row) => row._id))
                );
                formData.append("cc", ccEmail);
                formData.append(
                  "htmlEmailTemplate",
                  // @ts-ignore
                  `${editorRef.current.getContent() || ""}\n\n${
                    !!signatureRes?.signature ? signatureRes?.signature : ""
                  }`
                );
                formData.append(
                  "html",
                  // @ts-ignore
                  `${editorRef.current.getContent() || ""}\n\n${
                    !!signatureRes?.signature ? signatureRes?.signature : ""
                  }`
                );
                formData.append("timeoutSeconds", _.toString(timeoutSeconds));

                setEmailRequestData(formData);

                //@ts-ignore
                const previewRes = await fetch(previewUrl, {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                  method: "POST",
                  body: formData,
                });

                const previewResJson = await previewRes.json();
                //@ts-ignore
                setPreviewEmails(_.omit(previewResJson, ["errors"]));
                setTopLevelErrors(_.get(previewResJson, "errors", []));

                setEmailSendLoading(false);
              } catch (error) {
                console.log(error);
                context?.setEmailMessage({
                  code: MessageStatus.ERROR,
                  message: JSON.stringify(error),
                });
              }
            }}
            disabled={
              isMissingEmail ||
              exceedsAttachmentSizeLimit ||
              _.isNil(emailConfig) ||
              _.eq(emailConfig, "none")
            }
          >
            Preview Email
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

// Display previewed emails in a modal
function ShowPreviewEmails(props: {
  previewEmails: any;
  setPreviewEmails: any;
  formData: any;
  context: any;
  navigate: any;
  emailConfig: undefined | String;
  sendUrl: undefined | String;
  topLevelErrors: any;
  setTopLevelErrors: any;
}) {
  const {
    previewEmails,
    setPreviewEmails,
    context,
    formData,
    navigate,
    emailConfig,
    sendUrl,
    topLevelErrors,
    setTopLevelErrors,
  } = props;

  const [emailLoading, setEmailLoading] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const [selectedEmail, setSelectedEmail] = useState<any>(
    Object.keys(previewEmails)[0]
  );
  const selectedEmailContent = previewEmails[selectedEmail];

  const senderEmail =
    !!user && !!user["https://crm.mercero-api.com/primaryEmailAddress"] ? (
      user["https://crm.mercero-api.com/primaryEmailAddress"]
    ) : (
      <span style={{ color: "red" }}>
        Please connect an email in your settings page.
      </span>
    );

  useEffect(() => {
    if (Object.keys(previewEmails)?.length > 0)
      setSelectedEmail(Object.keys(previewEmails)[0]);
  }, [previewEmails]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    padding: "20px !important",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
    "& .MuiBox-root": {
      padding: "10px",
    },
  };

  return (
    <Dialog
      open={Object.keys(previewEmails)?.length > 0}
      onClose={() => {
        setPreviewEmails({});
        setTopLevelErrors({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      fullWidth
    >
      {emailLoading && <LoadingScreen />}
      <DialogContent>
        {/* <Box sx={style}> */}
        {_.chain(topLevelErrors).size().gt(0).value() ? (
          <Box m={1}>
            <Alert severity="error">{`${_.join(
              topLevelErrors,
              ", "
            )}. Please fix to send email(s). Click Cancel to return to email composer.`}</Alert>
          </Box>
        ) : (
          <></>
        )}
        <Grid container style={{ maxHeight: "60vh" }}>
          <Grid item xs={8} style={{ maxHeight: "60vh" }}>
            {!!!selectedEmail && (
              <Typography variant="h6">No email selected</Typography>
            )}
            {!!selectedEmail && (
              <>
                <Typography fontSize={"14px"} style={{ marginBottom: "15px" }}>
                  <b>From</b>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${user?.name} (${senderEmail})`}
                    style={{ marginLeft: "5px" }}
                  />
                </Typography>
                <Typography fontSize={"14px"}>
                  <b>To</b>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={`${selectedEmailContent?.message?.recipient}`}
                    style={{ marginLeft: "5px" }}
                  />
                </Typography>
                <div style={{ marginTop: "15px", padding: "20px" }}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "600", marginBottom: "20px" }}
                  >
                    {selectedEmailContent?.message?.subject}
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedEmailContent?.message?.content,
                    }}
                  />
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={4} style={{ height: "60vh" }}>
            <Typography variant="h6">Recipients</Typography>
            {_.chain(previewEmails)
              .values()
              .map((o) => _.get(o, "errors", []))
              .flatten()
              .some((o) => _.size(o) > 0)
              .value() ? (
              <Alert severity="error">Found errors</Alert>
            ) : (
              <></>
            )}
            <List
              style={{ maxHeight: "calc(100% - 40px)", overflowY: "scroll" }}
            >
              {Object.keys(previewEmails).map((emailKey) => {
                const email = previewEmails[emailKey];
                return (
                  <ListItemButton
                    key={emailKey}
                    onClick={() => {
                      setSelectedEmail(emailKey);
                    }}
                    selected={selectedEmail === emailKey}
                  >
                    <ListItemIcon>
                      {!!email?.errors[0] ? (
                        <ErrorIcon sx={{ color: "red" }} />
                      ) : (
                        <CheckCircleIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={email?.message?.recipient || email?.errors[0]}
                      //@ts-ignore
                      secondary={
                        !!email?.errors[0]
                          ? // @ts-ignore
                            _.chain(email).get("errors", []).join(", ").value()
                          : "No errors found"
                      }
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Grid>
        </Grid>
        {/* </Box> */}
      </DialogContent>
      <DialogActions
        style={{
          borderTop: "1px solid rgba(0,0,0,.2)",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        <Divider style={{ marginBottom: "10px" }} />
        <Button
          variant="outlined"
          style={{ float: "left", textTransform: "none" }}
          onClick={() => {
            setPreviewEmails({});
            setTopLevelErrors({});
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ float: "right", textTransform: "none" }}
          onClick={async () => {
            try {
              setEmailLoading(true);
              const accessToken = await getAccessTokenSilently();

              //@ts-ignore
              const resp = await fetch(sendUrl, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
                method: "POST",
                body: formData,
              });

              setEmailLoading(false);

              const responseText = await new Response(resp?.body).text();
              let responseBody = {};
              if (!_.isNil(responseText) && !_.isEmpty(responseText)) {
                responseBody = JSON.parse(responseText);
              }
              if (
                _.has(responseBody, "error") &&
                _.has(responseBody, "status")
              ) {
                context?.setEmailMessage({
                  code: MessageStatus.ERROR,
                  //@ts-ignore
                  message: JSON.stringify(responseBody?.error?.message?.client),
                });
              } else {
                context?.setEmailMessage({
                  code: MessageStatus.SUCCESS,
                  message: JSON.stringify("Email sent successfully"),
                });
                navigate("/campaigns");
              }
            } catch (error) {
              console.log(error);
              setEmailLoading(false);
              context?.setEmailMessage({
                code: MessageStatus.ERROR,
                message: JSON.stringify(error),
              });
            }
          }}
          disabled={
            _.isNil(emailConfig) ||
            _.eq(emailConfig, "none") ||
            !_.isEmpty(topLevelErrors)
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
