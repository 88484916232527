import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { apiURL } from "./consts";

export default function Signature(props: {
  setSuccessMessage: (value: React.SetStateAction<string | undefined>) => void;
}) {
  const { setSuccessMessage } = props;

  const editorRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const [signature, setSignature] = React.useState("");

  useEffect(() => {
    const getSignature = async () => {
      const accessToken = await getAccessTokenSilently();

      const getSignature = await fetch(`${apiURL}email/signature`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "GET",
      });

      const sigRes = await getSignature.json();
      setSignature(sigRes.signature);
    };
    if (!!!signature) {
      getSignature();
    }
  }, [signature, getAccessTokenSilently]);

  return (
    <>
      <Editor
        apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
        // @ts-ignore
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={signature}
        init={{
          height: 275,
          width: 600,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px, line-height: 1; }",
          force_br_newlines: true,
          forced_root_block: "div",
          force_p_newlines: false,
        }}
      />
      <Button
        style={{
          margin: "15px 0px",
          display: "block",
        }}
        variant="outlined"
        onClick={async () => {
          try {
            const accessToken = await getAccessTokenSilently();

            await fetch(`${apiURL}email/signature`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                // @ts-ignore
                signature: !!editorRef && editorRef.current?.getContent(),
              }),
            });

            setSuccessMessage("Signature saved successfully");
          } catch (e) {
            console.log(e);
          }
        }}
      >
        Save Signature
      </Button>
    </>
  );
}
