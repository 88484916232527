import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ALL_VIEWS, UPDATE_VIEW } from "./graphql-ops";
import { SocketIOContext } from './socketIOProvider'
import _ from 'lodash'
import { useAuth0 } from "@auth0/auth0-react";

const useUpsertView = () => {
  const { socket } = useContext(SocketIOContext) as any;

  const { user } = useAuth0();

  const userQuery = { userId: { auth0Sub: user?.sub }, isDeleted: false };

  const updateViewCache = (cache: any, upsertedView: any) => {
    const workspaceId = _.get(upsertedView,'workspaceId')
    // const rowId = _.get(upsertedView,'rowId._id')

    if (!_.isNil(upsertedView)) {

      const upsertedViewId = _.chain(upsertedView).get('_id').value();
      const isUpdatedViewDeleted = _.chain(upsertedView).get('isDeleted',false).value()

      const cacheId = cache.identify(upsertedView); // Get cache ID for the newly created view

      const updateCache = (payload: any) => {
        const { query, newView } = payload

        const newViewId = _.chain(newView).get('_id').value();

        // Read the current cache for the GET_ALL_VIEWS query
        const existingViews = cache.readQuery({
          query: GET_ALL_VIEWS,
          variables: { query },
        });

        let upsertedViews = [ newView ]

        // @ts-ignore
        if (existingViews && existingViews.views) {
          // Add the new view to the cached list
          //@ts-ignore
          const isUpdatedView = _.chain(existingViews).get('views',[]).filter((view: any) => _.chain(view).get('_id').value() === newViewId).size().eq(1).value()

          upsertedViews = _.chain(existingViews).get('views',[]).value()

          if (isUpdatedView) {
            if (isUpdatedViewDeleted) {
              // @ts-ignore
              upsertedViews = existingViews.views.filter((view: any) => _.chain(view).get('_id').value() !== newViewId);
            }
          } else {
            // @ts-ignore
            upsertedViews = [...upsertedViews, newView];
          }
        }

        // Write the updated list back to cache
        cache.writeQuery({
          query: GET_ALL_VIEWS,
          variables: { query },
          data: {
            views: upsertedViews,
          },
        });
      }

      updateCache({ query: { workspaceId, isDeleted: false }, newView: upsertedView})
    }
  }


  const [updateView, updateViewProps] = useMutation(UPDATE_VIEW, {
    update(cache, { data }) {
      const updatedViewId = data?.updateOneView?._id;

      updateViewCache(cache, data?.updateOneView)

      socket.emit('update-view', updatedViewId);
    },
  });


  return { updateViewCache, updateView, updateViewProps };
};

export default useUpsertView;
