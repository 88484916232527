import { useContext, useRef, useState } from "react";
import "./App.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import * as chrono from "chrono-node";
import { useAuth0 } from "@auth0/auth0-react";
import { Editor } from "@tinymce/tinymce-react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { apiURL } from "./consts";
import { RefetchContext } from "./refetchProvider";
import { initial, set } from "lodash";
import useCreateComment from "./useCreateComment";

import { useMutation } from "@apollo/client";
import {
  UPDATE_ONE_COMMENT,
  CREATE_NOTIFICATION_MUTATION,
} from "./graphql-ops";
import { useBlocker, useParams } from "react-router-dom";

export function NewNoteCollapsed(props: {
  showDetailView: any;
  setNotes: any;
  notes: any;
  initialValue?: any;
  setNoteToEdit?: any;
  refetchComments: any;
  setCreateNoteFromTodo?: any;
}) {
  const {
    showDetailView,
    setNotes,
    notes,
    initialValue,
    setNoteToEdit,
    refetchComments,
    setCreateNoteFromTodo,
  } = props;
  const editorRef: any = useRef(null);

  const [newNote, setNewNote] = useState(initialValue?.comment || "");
  const context = useContext(RefetchContext);

  const { listId } = useParams();

  const [file, setFile] = useState<any[]>([]);
  const { getAccessTokenSilently, user } = useAuth0();
  const [noteMembers, setNoteMembers] = useState<any[]>([]);

  const { createComment } = useCreateComment(listId || "");

  const [updateComment] = useMutation(UPDATE_ONE_COMMENT);
  const [createNotification] = useMutation(CREATE_NOTIFICATION_MUTATION);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      unsavedChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const handleNodeChange = (e: any) => {
    // @ts-ignore
    const selection = editorRef?.current?.selection?.getContent();
    const toolbar = document.querySelector(".tox-editor-header");
    const content = editorRef?.current?.getContent();

    if (content?.length > 0) {
      setUnsavedChanges(true);
    }

    if (toolbar) {
      if (selection && selection.length > 0) {
        // @ts-ignore
        toolbar.style.display = "flex";
        // @ts-ignore
        toolbar.style.width = "184px";
      } else {
        // @ts-ignore
        toolbar.style.display = "none";
      }
    }
  };

  const handleEditorInit = (editor: any) => {
    // Autocompleter configuration
    editor.ui.registry.addAutocompleter("myautocompleter", {
      ch: "@", // Add this line
      minChars: 0,
      columns: 1,
      //   smatches: /^\{/,
      fetch: (pattern: any) => {
        // const items = [
        //   { type: "autocompleteitem", value: "@Jake", text: "Jake" },
        //   { type: "autocompleteitem", value: "@Jim", text: "Jim" },
        //   { type: "autocompleteitem", value: "@John", text: "John" },
        // ];

        const items = context?.workspaceMembers?.map((member: any) => {
          return {
            type: "autocompleteitem",
            value: `@${member?.name}`,
            text: member?.name,
          };
        });

        // const items = ["@Jake", "@John", "@Jill", "@Jack"];

        return new Promise((resolve) => {
          const results = items?.filter((item) => {
            return item?.text?.toLowerCase().includes(pattern?.toLowerCase());
          });
          resolve(results);
        });
      },
      onAction: (autocompleteApi: any, rng: any, value: any) => {
        editor.selection.setRng(rng);

        const findUser = context?.workspaceMembers?.filter(
          (member: any) => member?.name === value?.substring(1)
        );

        if (!!findUser && findUser?.length > 0) {
          setNoteMembers([...noteMembers, findUser[0]]);
        }
        editor.insertContent(`<span class="mention">${value}</span> `);
        autocompleteApi.hide();
      },
    });
  };

  const submitComment = async () => {
    let candidateCommentId: any;
    let updatedNotes = notes;

    const comment = editorRef?.current?.getContent();

    if (!!initialValue) {
      try {
        const timestamp = new Date();

        const resp: any = await updateComment({
          variables: {
            query: {
              _id: initialValue._id,
            },
            set: {
              comment,
              rowId: {
                link: showDetailView._id || showDetailView["_id"],
              },
              userId: { link: user?.sub },
              updatedByUserId: { link: user?.sub },
              updatedAt: timestamp,
              triggeredBy: "client",
            },
          },
        });

        const insertedComment = resp?.data?.updateOneComment;

        candidateCommentId = insertedComment._id;

        const filterOutEditedNote = notes.filter(
          (note: any) => note._id !== insertedComment._id
        );

        updatedNotes = [
          ...filterOutEditedNote,
          { ...insertedComment, type: "note" },
        ];

        setNoteToEdit && setNoteToEdit(null);
        // // @ts-ignore
        // editorRef?.current?.setContent("");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const timestamp = new Date();

        const resp: any = await createComment({
          variables: {
            data: {
              comment,
              rowId: {
                link: showDetailView._id || showDetailView["_id"],
              },
              userId: { link: user?.sub },
              updatedByUserId: { link: user?.sub },
              createdAt: timestamp,
              updatedAt: timestamp,
              isDeleted: false,
              triggeredBy: "client",
            },
          },
        });

        setUnsavedChanges(false);

        const parsedTime = chrono.parse(comment, new Date(), {
          forwardDate: true,
        });

        if (!!parsedTime[0] && setCreateNoteFromTodo) {
          const commentText = editorRef?.current?.getContent({
            format: "text",
          });
          setCreateNoteFromTodo(commentText);
        }

        const insertedComment = resp?.data?.insertOneComment;

        candidateCommentId = insertedComment?._id;

        updatedNotes = [...notes, { ...insertedComment, type: "note" }];
      } catch (error) {
        console.log(error);
      }
    }

    const postProcess = async (un: any) => {
      setNotes(un);
      setNewNote("");
      setFile([]);

      // @ts-ignore?
      editorRef?.current?.setContent("");

      const activityTimestamp = new Date();

      const accessToken = await getAccessTokenSilently();

      for (const noteMember of noteMembers) {
        await createNotification({
          variables: {
            data: {
              initiatedByUserId: { link: user?.sub },
              commentId: { link: candidateCommentId },
              priority: "info",
              userId: { link: noteMember.auth0Sub },
              read: false,
              triggeredBy: "client",
              isDeleted: false,
            },
          },
        });

        const formData = new FormData();
        formData.append("commentBody", comment);
        //@ts-ignore
        formData.append("commentorId", user?.sub);
        formData.append("commenteeId", noteMember.auth0Sub);

        const accessToken = await getAccessTokenSilently();

        await fetch(`${apiURL}email/comment`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        })
          // .then((res) => res.json())
          // .then((result) => {
          // })
          .catch((err) => {
            console.log({ err });
          });
      }

      await refetchComments();
    };

    if (file && file[0] && candidateCommentId) {
      const formData = new FormData();
      formData.append("file", file[0]);

      const accessToken = await getAccessTokenSilently();

      await fetch(`${apiURL}comment/?commentId=${candidateCommentId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          postProcess(updatedNotes);
        });
    } else {
      postProcess(updatedNotes);
    }
  };

  return (
    <Grid
      style={{
        borderBottom: "1px solid rgba(0, 0, 0, .2)",
        padding: "12px 16px",
      }}
      container
    >
      <Dialog
        open={blocker.state === "blocked"}
        onClose={() => {
          setUnsavedChanges(false);
          !!blocker.reset && blocker?.reset();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"You have an unsaved comment. Do you want to proceed?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setUnsavedChanges(false);
              !!blocker.reset && blocker?.reset();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              !!blocker.proceed && blocker?.proceed();
            }}
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={8.5}>
        <Editor
          apiKey="sf3duu37n3516sap1ur5edu5dcvla052148mvggs0ue2zuit"
          key={showDetailView._id}
          // @ts-ignore
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={newNote}
          init={{
            // auto_focus: !!initialValue ? true : undefined,
            placeholder: "Add a note...",
            height: 175,
            width: "100%",
            menubar: false,
            outline: false,
            inline: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              //"link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            //toolbar_mode: "floating",
            toolbar: "bold italic underline bullist",
            content_style:
              "body { font-family:Inter,Helvetica,Arial,sans-serif; font-size:14px }",
            setup: (editor: any) => {
              editor.on("NodeChange", handleNodeChange);

              // editor.on("change", function (e: any) {
              //   var content = editor.getContent();

              //   // Regex for phone numbers (simplified and might need adjustments)
              //   var phoneRegex =
              //     /(\+?\d{1,4}[\s\-]?\(?\d{1,3}\)?[\s\-]?\d{1,4}[\s\-]?\d{1,4}(?:[\s\-]?\d{1,9})?)/g;
              //   // Regex for detecting URLs
              //   var urlRegex =
              //     /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
              //   // Regex for detecting email addresses
              //   var emailRegex =
              //     /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b)/gim;

              //   // Replace phone numbers with tel links
              //   content = content.replace(phoneRegex, function (match: any) {
              //     return (
              //       '<a href="tel:' +
              //       match.replace(/\D/g, "") +
              //       '">' +
              //       match +
              //       "</a>"
              //     );
              //   });

              //   // Replace URLs with clickable links
              //   content = content.replace(urlRegex, function (match: any) {
              //     return '<a href="' + match + '">' + match + "</a>";
              //   });

              //   // Replace email addresses with mailto links
              //   content = content.replace(emailRegex, function (match: any) {
              //     return '<a href="mailto:' + match + '">' + match + "</a>";
              //   });

              //   editor.setContent(content);
              // });

              editor.on("keydown", function (e: any) {
                if (
                  (e.metaKey || e.ctrlKey) &&
                  (e.key === "Enter" || e.keyCode === 13)
                ) {
                  e.stopPropagation();
                  e.preventDefault();

                  submitComment();
                }

                if (e.key === "Enter" || e.keyCode === 13) {
                  e.stopPropagation();
                }
              });

              handleEditorInit(editor);
            },
          }}
        />
      </Grid>
      <Grid item xs={3.5}>
        <div style={{ textAlign: "right" }}>
          {file.length > 0 && file.map((f) => f.name).join(", ")}
          <IconButton component="label">
            <AttachFileIcon
              style={{
                width: "20px",
                marginRight: "5px",
              }}
            />
            <input
              hidden
              multiple
              type="file"
              onChange={(event) => {
                // @ts-ignore
                setFile([event.target.files[0]]);
              }}
            />
          </IconButton>
          {!!initialValue && (
            <Button
              style={{
                border: "1px solid rgb(223, 225, 228)",
                height: "28px",
                fontSize: "13px",
                color: "rgb(60, 65, 73)",
                backgroundColor: "rgb(255, 255, 255)",
                boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
                textTransform: "none",
                fontWeight: 500,
                marginRight: "10px",
              }}
              onClick={async () => {
                setNoteToEdit && setNoteToEdit(null);
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            style={{
              border: "1px solid rgb(223, 225, 228)",
              height: "28px",
              fontSize: "11px",
              color: "rgb(60, 65, 73)",
              backgroundColor: "rgb(255, 255, 255)",
              boxShadow: "rgb(0 0 0 / 9%) 0px 1px 1px",
              textTransform: "none",
              fontWeight: 500,
            }}
            onClick={submitComment}
          >
            Comment
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
