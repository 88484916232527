import { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { GET_ROWS, CREATE_ROW_MUTATION } from "./graphql-ops";
import { SocketIOContext } from "./socketIOProvider";
import _ from "lodash";
import { GlobalContext } from "./globalContext";
import { RowContext, updateRowTags } from "./rowProvider";

const useCreateRow = (listId: string) => {
  const { socket } = useContext(SocketIOContext) as any;
  const globalContext = useContext(GlobalContext);
  const rowContext = useContext(RowContext);

  useEffect(() => {
    if (!_.isNil(socket) && !_.isNil(listId) && !_.isEmpty(listId)) {
      socket.emit("join-room", listId);
    }
  }, [listId, socket]);

  const [createRow, createRowProps] = useMutation(CREATE_ROW_MUTATION, {
    update(cache, { data: { insertOneRow } }) {
      const cacheId = cache.identify(insertOneRow); // Get cache ID for the newly created row

      // Read the current cache for the GET_ROWS query
      const existingRows = cache.readQuery({
        query: GET_ROWS,
        variables: { input: listId },
      });

      // @ts-ignore
      if (existingRows && existingRows.GetRowsByViewId) {
        // Add the new row to the cached list
        // @ts-ignore
        const updatedRows = [...existingRows.GetRowsByViewId, insertOneRow];

        const newMap = globalContext?.rows;
        const newRow = {
          ...insertOneRow,
          ...JSON.parse(insertOneRow?.rowObject),
        };
        // @ts-ignore
        newMap?.[listId]?.set(insertOneRow?._id, {
          ...newRow,
        });
        if (!!newMap) globalContext?.setAllRows({ ...newMap });

        // Update tags
        const view = insertOneRow.viewId;

        if (view) {
          updateRowTags(rowContext, listId, newRow, view.columns);
        }

        // Write the updated list back to cache
        cache.writeQuery({
          query: GET_ROWS,
          variables: { input: listId },
          data: {
            GetRowsByViewId: updatedRows,
          },
        });

        // Also consider adding the new row to cache individually
        cache.modify({
          id: cacheId,
          fields: {
            rowId() {
              return insertOneRow.rowId;
            },
            rowObject() {
              return insertOneRow.rowObject;
            },
            // ... add other fields as needed
          },
        });
      }
      socket.emit("update-row", listId, insertOneRow._id);
    },
  });

  return { createRow, ...createRowProps };
};

export default useCreateRow;
